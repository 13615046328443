@font-face {
    font-family: metropolis-extra-light;
    src: url(../public/Metropolis-ExtraLight.otf);
}

.metropolis-extra-light {
    font-family: metropolis-extra-light;
}

@font-face {
    font-family: metropolis-thin;
    src: url(../public/Metropolis-Thin.otf);
}

.metropolis-thin {
    font-family: metropolis-thin;
}

@font-face {
    font-family: metropolis-regular;
    src: url(../public/Metropolis-Regular.otf);
}

.metropolis-regular {
    font-family: metropolis-regular;
}
