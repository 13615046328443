img {
    max-width: 100%;
}

.gallery {
    padding: 80px 0;
}

.gallery img {
    padding: 15px;
}

.gallery-text {
    text-align: center;
    font-size: 1rem;
}

.gallery-text a {
    text-decoration: none !important;
    color: white !important;
}

.gallery-text a:hover {
    color: #cccccc !important;
}


.menu {
    overflow: hidden;
    top: 0;
    width: 100%;
    padding: 3rem;
    font-size: 1.2rem;
    padding-bottom: 1rem;
}
  
.menu a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.menu a:hover {
    color: #cccccc !important;
}

.center-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 70vh !important;
}

.menu-quote {
    margin-top: 50px;
}

