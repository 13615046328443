.about {
    margin: 2rem;
}

.about-image {
    width: 300px;
}

.about-text {
    text-align: justify;
    padding: 1rem;
    margin: auto;
    font-size: .8rem;
}

.about-text-container {
    width: 300px;
    display: flex;
    align-items: flex-end;
}