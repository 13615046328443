.home {
    color: white;
}

.home-image {
    width: 300px;
}

.home-title {
    margin: 2rem;
}

.home {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    align-items: center;
    padding: 4rem;
}

.home-cover {
    position: relative;
    overflow: hidden;
    height: 90%;

    img {
        display: block;
        width: 100%;
        height: 90%;
        object-fit: cover;
        margin-top: 1rem;
    }
}

.lazy {
    width: 100%;
}