.molting {
    font-size: .8rem;
    text-align: center;
}

.molting img {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
}

.molting-title {
    margin-bottom: 5px;
    font-size: 17px;
    letter-spacing: 1px;
    text-align: start;
}

.molting-subtitle {
    margin-bottom: 5px;
    font-size: 17px;
    letter-spacing: 1px;
    text-align: start;
    font-size: 12px;
}


.molting-spacing {
    padding: 1rem !important;
}

.molting-text-justify {
    text-align: justify !important;
}

.molting-text-right {
    text-align: right !important;
}

.molting-text-center {
    text-align: center;
}

.molting-text-left {
    text-align: left !important;
}

.molting-text-center-bottom {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: .5rem !important;
}

.molting-special-padding img {
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 0px !important;
}

.molting-special-right-padding img {
    padding-top: 2rem;
    padding-left: 2rem;
    padding-bottom: 2rem;
    padding-right: 0px !important;
}

.molting-special-sides-padding {
    padding-top: 0 !important;
    padding-left: .5rem !important;
    padding-bottom: 0 !important;
    padding-right: .5rem !important;
}

.image-cover {
    height: 90vh;
    img {
        height: 100%;
    }
}