.raiz-hambre {
    font-size: .8rem;
}

.raiz-hambre img {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    padding-bottom: 2rem !important;
    padding-top: 2rem !important;
}

.raiz-hambre-txt {
    margin-top: 3rem !important;
}

.raiz-hambre-text {
    text-align: justify;
    font-size: .8rem;
    padding: 2rem !important;
}

.raiz-hambre-text-center {
    margin: auto !important;
    font-size: .8rem;
    text-align: center;
}

.raiz-hambre-img-center {
    margin: auto !important;
}

.raiz-hambre-title {
    margin-bottom: 5px;
    font-size: 17px;
    letter-spacing: 1px;
}

.raiz-hambre-subtitle {
    margin-bottom: 2.5rem;
    font-size: 12px;
    letter-spacing: 1px;
}

.raiz-hambre-contact-me {
    a {
        float: initial !important;
        text-decoration: none;
        color: #959596;
    }
    
}