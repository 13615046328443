.contact-container {
    margin: 1.5rem !important;
}

.contact-text {
    text-align: justify;
    font-size: .8rem;
}

.contact-text a {
    font-size: .8rem;
    text-decoration: none !important;
    color: white !important;
}

.contact-text a:hover {
    color: #cccccc !important;
}

.contact-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
}